import { LocalService } from './../../core/services/local.service';
import { OrderService } from './../../core/services/order.service';
import { ActionButtonOrderList, ActionButtonOrderTransaction } from './../components/action-button-order-list.component';
import { DataTypeValidationService } from './../../core/services/data-type-validation.service';
import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { HeaderNavigateModel } from 'src/app/core/models/common/headernavigate.model';
import { HeaderNavigateService } from 'src/app/core/services/headernavigate.service';
import { ColDef } from 'ag-grid-community';
import * as exportFile from 'xlsx';
import * as moment from 'moment';
import { Router } from '@angular/router';

declare const bootstrap: any;

@Component({
    selector: 'app-order',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit, AfterViewInit {

    searchOption: any = {
        q: {
            orderNo: '',
            docNo: '',
            invoiceNumber: '',
            volumeAboAccountCode: '',
            taxPayerGivenName: '',
            orderDateTimeFrom: new Date(),
            orderDateTimeTo: new Date(),
            status: ['SUCCESS', 'FAILED'],
        },
        pageinfo: {
            page: 1,
            rowPerPage: 10,
            totalRecord: 0,
            allpage: 0
        }
    };
    searched: boolean = false;
    searchByDate: boolean = true;
    currentDatetime: any = '';
    orderColumnDefs: ColDef[] = [
        {
            headerName: 'Action',
            cellRenderer: 'actionOrderList',
            cellRendererParams: {
                clickedOpenView: (data: any) => {
                    this.openView(data);
                },
                clickedDownloadZip: (data: any) => {
                    this.modelDownloadZip(data);
                },
                clickedSendEmail: (data: any) => {
                    this.sendEmail(data);
                }
            },
            width: 270
        },
        { headerName: "Order Date", field: 'orderDateTime', sortable: true, width: 170 },
        { headerName: 'Order No', field: 'orderNo', sortable: true, width: 150 },
        { headerName: 'Invoice No', field: 'invoiceNumber', sortable: true, width: 150 },
        { headerName: 'Num Of Doc', field: 'numOfDoc', sortable: true, width: 120 },
        { headerName: 'ABO', field: 'abo', sortable: true, width: 320 },
        { headerName: 'Status', field: 'status', sortable: true, width: 100 },
        {
            headerName: 'Last Update',
            cellRenderer: 'actionOrderTransaction',
            cellRendererParams: {
                clickedOpenTransaction: (data: any) => {
                    this.openTransaction(data);
                }
            },
            width: 200
        }
    ];

    public defaultColDef: ColDef = {
        sortable: true,
        filter: false,
        resizable:true,
        autoHeight:true,
        wrapText:true,
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        return 0;
        },
        sortingOrder: ['asc', 'desc']
    };

    frameworkComponents = {
        actionOrderList: ActionButtonOrderList,
        actionOrderTransaction: ActionButtonOrderTransaction
    }

    orderData: any = [];
    messageAlertModal: any;
    messageAlert: any = {
        title: '',
        message: '',
        button: []
    }
    loadingModal: any;

    constructor(
        private headerNavigateService: HeaderNavigateService,
        private dataTypeValidationService: DataTypeValidationService,
        private orderService: OrderService,
        private localService: LocalService,
        private router: Router,
         private changeDetector: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {
        this.initialData();
    }

    ngAfterViewInit(): void {
        this.messageAlertModal = new bootstrap.Modal(document.getElementById('messageAlertModal'));
        this.loadingModal = new bootstrap.Modal(document.getElementById('loadingModal'), { backdrop: 'static', keyboard: false });
    }

    ngOnDestroy() {
        const messageAlertModal: any = document.getElementById('messageAlertModal');
        messageAlertModal.remove();
        const loadingModal: any = document.getElementById('loadingModal');
        loadingModal.remove();
    }

    numPage(n: number): Array<number> {
        return Array(n);
    }

    initialData() {
        let headerNavigateModelList: Array<HeaderNavigateModel> = [
            { label: 'Home', url: '/', attributes: { title: 'Home' } },
            { label: 'Search Order', url: '/ordersearch' }
        ];
        this.headerNavigateService.setNavigate(headerNavigateModelList);

    }

    async searchOrderData() {
        if (this.validateSearchField()) {
            if (this.validateSearchFieldType()) {
                await this.getOrders();
            }
        }
    }

    async getOrders() {
        this.loadingModal.show();
        const regex = new RegExp("(^[\\s]+)|[\\s]+$", 'g');
        let dateFrom: any = moment(this.searchOption.q.orderDateTimeFrom).format('YYYY-MM-DDT00:00:00+07:00'),
            dateTo: any = moment(this.searchOption.q.orderDateTimeTo).format('YYYY-MM-DDT23:59:59+07:00');
        let option: any = {
            q: {
                orderNo: (this.searchOption.q.orderNo) ? '200-' + this.searchOption.q.orderNo : null,
                docNo: (this.searchOption.q.docNo) ? this.searchOption.q.docNo.replace(regex, '') : null,
                invoiceNumber: (this.searchOption.q.invoiceNumber) ? this.searchOption.q.invoiceNumber : null,
                volumeAboAccountCode: (this.searchOption.q.volumeAboAccountCode) ? this.searchOption.q.volumeAboAccountCode : null,
                taxPayerGivenName: (this.searchOption.q.taxPayerGivenName) ? this.searchOption.q.taxPayerGivenName.replace(regex, '') : null,
                orderDateTimeFrom: (this.searchByDate && this.searchOption.q.orderDateTimeFrom) ? encodeURIComponent(dateFrom) : null,
                orderDateTimeTo: (this.searchByDate && this.searchOption.q.orderDateTimeTo) ? encodeURIComponent(dateTo) : null,
                status: (this.searchOption.q.status.length > 1) ? 'ALL' : this.searchOption.q.status[0],
            },
            pageinfo: this.searchOption.pageinfo
        }
        this.orderService.getOrders(option).subscribe(
            {
                next: async (result: any) => {
                    if (!result.data.length && parseInt(result.pageinfo.page) != 1) {
                        this.searchOption.pageinfo.page = 1;
                        this.getOrders();
                    }
                    else {
                        this.orderData = await this.setData(result.data);
                        this.searchOption.pageinfo.page = parseInt(result.pageinfo.page);
                        this.searchOption.pageinfo.rowPerPage = parseInt(result.pageinfo.rowPerPage);
                        this.searchOption.pageinfo.totalRecord = parseInt(result.pageinfo.totalRecord);
                        this.searchOption.pageinfo.allpage = Math.ceil(result.pageinfo.totalRecord / result.pageinfo.rowPerPage);
                        this.searched = true;
                        this.currentDatetime = moment().format('DD/MM/YYYY HH:mm:ss');
                        this.changeDetector.detectChanges();
                    }
                    setTimeout(() => {
                        this.loadingModal.hide();
                    }, 500);
                },
                error: (error: any) => {
                    setTimeout(() => {
                        this.loadingModal.hide();
                        const self = this;
                        this.messageAlert = {
                            title: 'Warning',
                            message: error.error.error.errorMsg,
                            button: [
                                {
                                    label: 'Close', action: async function () {
                                        self.messageAlertModal.hide();
                                    }.bind(this)
                                }]
                        }
                        this.messageAlertModal.show();
                    }, 500);
                }
            }
        );
    }

    async setData(orderData: any) {
        return orderData.map((row: any) => {
            row.orderNo = row.orderNo.split('-')[1];
            row.orderDateTime = moment(row.orderDateTime).format('DD/MM/YYYY HH:mm:ss');
            row.abo = row.volumeAboAccountCode + ' ' + ((row.taxPayerGivenName) ? row.taxPayerGivenName : '');
            row.etaxStatusDateTime = moment(row.etaxStatusDateTime).format('DD/MM/YYYY HH:mm:ss');
            row.docDatetime = moment(row.docDatetime).format('DD/MM/YYYY HH:mm:ss');
            row.eventDate = moment(row.eventDate).format('DD/MM/YYYY HH:mm:ss');
            row.invoiceDatetime = moment(row.invoiceDatetime).format('DD/MM/YYYY HH:mm:ss');
            row.inputKinesisStatusDatetime = moment(row.inputKinesisStatusDatetime).format('DD/MM/YYYY HH:mm:ss');
            row.docPdfFileStatusDatetime = moment(row.docPdfFileStatusDatetime).format('DD/MM/YYYY HH:mm:ss');
            row.docOutputInetStatusDatetime = moment(row.docOutputInetStatusDatetime).format('DD/MM/YYYY HH:mm:ss');
            return row;
        });
    }

    async gotoPage(page: any) {
        if (page > 0 && page <= this.searchOption.pageinfo.allpage) {
            this.searchOption.pageinfo.page = page;
            await this.searchOrderData();
        }
    }

    getShowingFrom() {
        let showingFrom: number = ((this.searchOption.pageinfo.rowPerPage * this.searchOption.pageinfo.page) - (this.searchOption.pageinfo.rowPerPage - 1));
        return (showingFrom < this.searchOption.pageinfo.totalRecord) ? showingFrom : this.searchOption.pageinfo.totalRecord;
    }

    getShowingTo() {
        let showingTo: number = this.searchOption.pageinfo.page * this.searchOption.pageinfo.rowPerPage;
        return (showingTo < this.searchOption.pageinfo.totalRecord) ? showingTo : this.searchOption.pageinfo.totalRecord;
    }

    resetSearch() {
        this.searchOption.q = {
            orderNo: '',
            docNo: '',
            invoiceNumber: '',
            volumeAboAccountCode: '',
            taxPayerGivenName: '',
            orderDateTimeFrom: this.searchOption.q.orderDateTimeFrom,
            orderDateTimeTo: this.searchOption.q.orderDateTimeTo,
            status: ['SUCCESS', 'FAILED'],
        }
        const searchTypeDuccess: any = document.getElementById('search_status_success');
        searchTypeDuccess.checked = true;
        const searchTypeFail: any = document.getElementById('search_status_fail');
        searchTypeFail.checked = true;
        this.searchByDate = true;
    }

    onCheckboxStatusChange(param: any) {
        if (param.target.checked) {
            this.searchOption.q.status.push(param.target.value);
        } else {
            const index = this.searchOption.q.status.indexOf(param.target.value);
            if (index > -1) {
                this.searchOption.q.status.splice(index, 1);
            }
        }
    }

    validateSearchField() {
        let checkedField: any = [];

        if (!this.searchByDate) {
            for (let field in this.searchOption.q) {
                if(field == 'status' || field == 'orderDateTimeFrom' || field == 'orderDateTimeTo'){
                    continue;
                }
                checkedField.push((this.searchOption.q[field]) ? true : false);
            }
        }

        let isCheckedFieldValid = !(checkedField.length && checkedField.every((field: boolean) => field == false));
        if (!isCheckedFieldValid) {
            const self = this;
            this.messageAlert = {
                title: 'Warning',
                message: 'Please enter at least 1 search condition',
                button: [
                    {
                        label: 'Close', action: async function () {
                            self.messageAlertModal.hide();
                        }.bind(this)
                    }]
            }
            this.messageAlertModal.show();
        }

        return isCheckedFieldValid;
    }

    validateSearchFieldType() {
        let checked: any = true,
            messageError: any = '';
        if (this.searchOption.q.orderNo && (!this.validateNumber(this.searchOption.q.orderNo) || this.searchOption.q.orderNo.length > 20)) {
            checked = false;
            messageError = 'order No is invalid';
        }
        else if (this.searchOption.q.invoiceNumber && (!this.validateNumber(this.searchOption.q.invoiceNumber) || this.searchOption.q.invoiceNumber.length > 20)) {
            checked = false;
            messageError = 'Invoice No is invalid';
        }
        else if (this.searchOption.q.docNo && this.searchOption.q.docNo.length > 20) {
            checked = false;
            messageError = 'Credit Note No is invalid';
        }
        else if (this.searchOption.q.volumeAboAccountCode && (!this.validateNumber(this.searchOption.q.volumeAboAccountCode) || this.searchOption.q.volumeAboAccountCode.length > 20)) {
            checked = false;
            messageError = 'ABO No is invalid';
        }
        else if (this.searchOption.q.taxPayerGivenName && this.searchOption.q.taxPayerGivenName.length > 50) {
            checked = false;
            messageError = 'ABO Name is invalid';
        }
        else if ((this.searchOption.q.orderDateTimeFrom && this.searchOption.q.orderDateTimeTo) && (this.searchOption.q.orderDateTimeFrom > this.searchOption.q.orderDateTimeTo)) {
            checked = false;
            messageError = 'From Date must not be less than To Date';
        }

        if (!checked) {
            const self = this;
            this.messageAlert = {
                title: 'Warning',
                message: messageError,
                button: [
                    {
                        label: 'Close', action: async function () {
                            self.messageAlertModal.hide();
                        }.bind(this)
                    }
                ]
            }
            this.messageAlertModal.show();
        }

        return checked;
    }

    validateNumber(input: any) {
        return this.dataTypeValidationService.validateNumber(input);
    }

    openView(data: any) {
        const url = this.router.serializeUrl(
            this.router.createUrlTree(['/ordersearch', '200-' + data.orderNo])
        )
        window.open(url, '_blank');
    }

    openTransaction(data: any) {
        const option: any = {
            orderNo: data.orderNo.replaceAll('200-', ''),
            startDate: moment(this.searchOption.q.orderDateTimeFrom).format('YYYY-MM-DDT00:00:00'),
            endDate: moment(this.searchOption.q.orderDateTimeTo).format('YYYY-MM-DDT23:59:59'),
            statusSuccess: true,
            statusPending: true,
            statusFail: true,
        }
        this.localService.setJsonValue('optionTransaction', JSON.stringify(option));
        const url = this.router.serializeUrl(
            this.router.createUrlTree(['/transaction'])
        )
        window.open(url, '_blank');
    }

    setTextFormatForSearch(str: string) {
        return (str) ? str.toLowerCase().replaceAll(' ', '_') : '';
    }

    exportExcel() {
        const self = this;
        this.messageAlert = {
            title: 'Confirm',
            message: 'Export Excel file ?',
            button: [
                {
                    label: 'Yes',
                    color: 'primary',
                    action: async function () {
                        self.exportOrder('xlsx');
                        self.messageAlertModal.hide();
                    }.bind(this)
                },
                {
                    label: 'Cancel', action: async function () {
                        self.messageAlertModal.hide();
                    }.bind(this)
                }
            ]
        }
        this.messageAlertModal.show();
    }

    exportCSV() {
        const self = this;
        this.messageAlert = {
            title: 'Confirm',
            message: 'Export CSV file ?',
            button: [
                {
                    label: 'Yes',
                    color: 'primary',
                    action: async function () {
                        self.exportOrder('csv');
                        self.messageAlertModal.hide();
                    }.bind(this)
                },
                {
                    label: 'Cancel', action: async function () {
                        self.messageAlertModal.hide();
                    }.bind(this)
                }
            ]
        }
        this.messageAlertModal.show();
    }

    exportOrder(exportTo: any = 'xlsx'): any {
        this.loadingModal.show();
        let dateFrom: any = moment(this.searchOption.q.orderDateTimeFrom).format('YYYY-MM-DDT00:00:00+07:00'),
            dateTo: any = moment(this.searchOption.q.orderDateTimeTo).format('YYYY-MM-DDT23:59:59+07:00');
        let optionExport: any = {
            q: {
                orderNo: (this.searchOption.q.orderNo) ? '200-' + this.searchOption.q.orderNo : null,
                docNo: (this.searchOption.q.docNo) ? this.searchOption.q.docNo : null,
                invoiceNumber: (this.searchOption.q.invoiceNumber) ? this.searchOption.q.invoiceNumber : null,
                volumeAboAccountCode: (this.searchOption.q.volumeAboAccountCode) ? this.searchOption.q.volumeAboAccountCode : null,
                taxPayerGivenName: (this.searchOption.q.taxPayerGivenName) ? this.searchOption.q.taxPayerGivenName : null,
                orderDateTimeFrom: (this.searchByDate && this.searchOption.q.orderDateTimeFrom) ? encodeURIComponent(dateFrom) : null,
                orderDateTimeTo: (this.searchByDate && this.searchOption.q.orderDateTimeTo) ? encodeURIComponent(dateTo) : null,
                status: (this.searchOption.q.status.length > 1) ? 'ALL' : this.searchOption.q.status[0],
            },
            pageinfo: {
                page: 1,
                rowPerPage: (this.searchOption.pageinfo.totalRecord) ? this.searchOption.pageinfo.totalRecord : 1
            }
        }
        this.orderService.getOrders(optionExport).subscribe(
            {
                next: async (result: any) => {
                    const orders: any = await this.setData(result.data);
                    let data: any = [
                        ['event', 'docType', 'eventType', 'eventDate', 'eTaxStatus', 'etaxStatusDateTime', 'orderNo',
                            'orderDateTime', 'docNo', 'docDatetime', 'docRemark', 'docChannel', 'invoiceNumber',
                            'invoiceDatetime', 'blockPvbvPrintingFlag', 'blockPvbvPrintingEffective',
                            'blockPvbvPrintingExpiration', 'inputKinesisStatus', 'inputKinesisStatusDatetime',
                            'docOutputInetStatus', 'docOutputInetStatusDatetime', 'docPdfFileStatus',
                            'docPdfFileStatusDatetime', 'accountCode', 'accountName', 'accountBusinessNature',
                            'volumeAboAccountCode', 'volumeAboName', 'volumeAboBusinessNature', 'taxId',
                            'taxPayerGivenName', 'taxPayerFamilyName'
                        ]
                    ];
                    if (orders.length) {
                        for (let order of orders) {
                            let newRow = []
                            for (const key of data[0]) {
                                newRow.push(order[key]);
                            }
                            data.push(newRow)
                        }
                    }
                    let fileOption: any = { bookType: exportTo, bookSST: true, type: 'binary' },
                        sheetName = 'Sheet1',
                        worksheet = exportFile.utils.json_to_sheet(data, { skipHeader: true }),
                        workbook = exportFile.utils.book_new(),
                        fileName = `export-order-${moment().format('DDMMYYYYHHSS')}.${fileOption.bookType}`;

                    exportFile.utils.book_append_sheet(workbook, worksheet, sheetName);
                    exportFile.writeFile(workbook, fileName, fileOption);
                    setTimeout(() => {
                        this.loadingModal.hide();
                    }, 500);
                },
                error: (error: any) => {
                    console.log(error);
                    setTimeout(() => {
                        this.loadingModal.hide();
                    }, 500);
                }
            }
        );

    }

    modelDownloadZip(data: any) {
        const self = this;
        this.messageAlert = {
            title: 'Confirm',
            message: 'Export PDF file ?',
            button: [
                {
                    label: 'Yes',
                    color: 'primary',
                    action: async function () {
                        self.messageAlertModal.hide();
                        self.downloadZipFile('200-' + data.orderNo);
                    }.bind(this)
                },
                {
                    label: 'Cancel', action: async function () {
                        self.messageAlertModal.hide();
                    }.bind(this)
                }
            ]
        }
        this.messageAlertModal.show();
    }

    downloadZipFile(orderNo: any) {
        this.orderService.orderExportZip(orderNo).subscribe(
            {
                next: async (result: any) => {
                    const buffer = this.base64ToBuffer(result);
                    let blob: any = new Blob([buffer], { type: 'application/zip' });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.setAttribute('target', '_blank');
                    link.setAttribute('href', url);
                    link.setAttribute('download', `${orderNo}.zip`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                },
                error: (error: any) => {
                    console.log('error : ', error);
                }
            }
        );
    }

    base64ToBuffer(b64Data: any, contentType = 'application/zip', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    sendEmail(data: any) {
        const self = this;
        this.messageAlert = {
            title: 'Confirm',
            message: 'Send mail to ABO ?',
            button: [
                {
                    label: 'Yes',
                    color: 'primary',
                    action: async function () {
                        self.eventSendEmail(data);
                        self.messageAlertModal.hide();
                    }.bind(this)
                },
                {
                    label: 'Cancel', action: async function () {
                        self.messageAlertModal.hide();
                    }.bind(this)
                }
            ]
        }
        this.messageAlertModal.show();
    }

    eventSendEmail(data: any) {
        this.loadingModal.show();
        let dataBody: any = {
            sendMailType: "INVOICE_ZIP",
            sendToABO: data.accountCode.split(' ')[0],
            orderNo: '200-' + data.orderNo
        };

        this.orderService.sendEmail(dataBody).subscribe(
            {
                next: async (result: any) => {
                    const self = this;
                    this.messageAlert = {
                        title: 'Success!',
                        message: 'Send Email successed',
                        button: [
                            {
                                label: 'Close', action: async function () {
                                    self.messageAlertModal.hide();
                                }.bind(this)
                            }
                        ]
                    }
                    setTimeout(() => {
                        this.loadingModal.hide();
                        this.messageAlertModal.show();
                    }, 500);
                },
                error: (error: any) => {
                    const self = this;
                    this.messageAlert = {
                        title: 'Error!',
                        message: error.error.error.errorMsg,
                        button: [
                            {
                                label: 'Close', action: async function () {
                                    self.messageAlertModal.hide();
                                }.bind(this)
                            }
                        ]
                    }
                    setTimeout(() => {
                        this.loadingModal.hide();
                        this.messageAlertModal.show();
                    }, 500);
                }
            }
        );
    };
}
