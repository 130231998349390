import { ActionButtonTransaction } from './../../components/action-button-transaction.component';
import { LocalService } from './../../../core/services/local.service';
import { ReconcileService } from './../../../core/services/reconcile.service';
import { Component, OnInit } from '@angular/core';
import { HeaderNavigateModel } from 'src/app/core/models/common/headernavigate.model';
import { HeaderNavigateService } from 'src/app/core/services/headernavigate.service';
import { DataTypeValidationService } from './../../../core/services/data-type-validation.service';
import * as moment from 'moment';
import * as exportFile from 'xlsx';
import { ColDef } from 'ag-grid-community';
import { ActivatedRoute } from '@angular/router';

declare const bootstrap: any;

export interface  TransactionModel {
    process?: string | undefined |null,
    step?: string  | undefined |null,
    startDate?: Date  | undefined |null,
    endDate?: Date  | undefined |null,
    orderNo?: string  | undefined |null,
    docNo?: string  | undefined |null,
    statusSuccess?: boolean  | undefined |null,
    statusPending?: boolean  | undefined |null,
    statusFail?: boolean  | undefined |null,
    error?: string  | undefined |null
}

@Component({
    selector: 'app-transaction',
    templateUrl: './transaction.component.html',
    styleUrls: ['./transaction.component.scss']
})
export class TransactionComponent implements OnInit {

    searchOption: {
        q:TransactionModel,
        pageinfo :any
    } = {
        q: {
            process: 'all',
            step: 'get_order_from_nextgen',
            startDate: new Date(),
            endDate: new Date(),
            orderNo: '',
            docNo: '',
            statusSuccess: true,
            statusPending: true,
            statusFail: true,
        },
        pageinfo: {
            page: 1,
            rowPerPage: 10,
            totalRecord: 0,
            allpage: 0
        }
    };

    orderColumnDefs: ColDef[] = [
        { headerName: 'Process', field: 'process', sortable: true, width: 150 },
        { headerName: 'Data Step', field: 'dataStep', sortable: true, width: 220 },
        { headerName: 'Order No', field: 'orderNo', sortable: true, width: 150 },
        { headerName: 'Doc No.', field: 'invCnNo', sortable: true, width: 150 },
        { headerName: 'Date', field: 'eventDate', sortable: true, width: 220 },
        { headerName: 'INET TransCode', field: 'transactionCode', sortable: true, width: 320 },
        { headerName: 'Result status', field: 'eventStatus', sortable: true, width: 150 },
        {
            headerName: 'Log',
            cellRenderer: 'actionLog',
            cellRendererParams: {
                clickedOpenView: (data: any) => {
                    this.openView(data);
                },
            },
            width: 200
        },
    ];
    frameworkComponents = {
        actionLog: ActionButtonTransaction,
    }

    minDate: Date = new Date(moment().startOf('month').add(-1, 'month').format('MM-DD-YYYY'));
    maxDate: Date = new Date(moment().endOf('month').format('MM-DD-YYYY'));

    orderData: any = [];

    messageAlertModal: any;
    messageAlert: any = {
        title: '',
        message: '',
        button: []
    }
    loadingModal: any;
    logModal: any;
    logData: any = null;
    currentDatetime: any = '';

    constructor(
        private headerNavigateService: HeaderNavigateService,
        private dataTypeValidationService: DataTypeValidationService,
        private activatedRoute: ActivatedRoute,
        private reconcileService: ReconcileService,
        private localService: LocalService,
    ) {
       if(history.state?.isDefaltTransaction){
           const optionDefalt: any = {
        process: 'all',
        step: 'get_order_from_nextgen',
        statusSuccess: true,
        statusPending: true,
        statusFail: true,
        startDate: new Date(moment().format('MM-DD-YYYY')),
        endDate: new Date(moment().format('MM-DD-YYYY'))
       };
       this.localService.setJsonValue('optionTransaction', JSON.stringify(optionDefalt));
       }

        const option: any = JSON.parse(this.localService.getJsonValue('optionTransaction'));
        if (option) {
            this.searchOption.q.process = (option.process) ? option.process : 'all';
            this.searchOption.q.step = (option.step) ? option.step : 'get_order_from_nextgen';
            this.searchOption.q.orderNo = (option.orderNo) ? option.orderNo : '';
            this.searchOption.q.statusSuccess = (option.statusSuccess) ? option.statusSuccess : false;
            this.searchOption.q.statusPending = (option.statusPending) ? option.statusPending : false;
            this.searchOption.q.statusFail = (option.statusFail) ? option.statusFail : false;
            this.searchOption.q.startDate = (option.startDate) ? new Date(option.startDate) : new Date(moment().format('MM-DD-YYYY'));
            this.searchOption.q.endDate = (option.endDate) ? new Date(option.endDate) : new Date(moment().format('MM-DD-YYYY'));
            this.localService.remove('optionTransaction');
        }


    }

    ngOnInit(): void {
        this.initialData();
        if(!history.state?.isDefaltTransaction){
            this.getTransaction();
        }
    }

    ngAfterViewInit(): void {
        this.messageAlertModal = new bootstrap.Modal(document.getElementById('messageAlertModal'));
        this.logModal = new bootstrap.Modal(document.getElementById('logModal'));
        this.loadingModal = new bootstrap.Modal(document.getElementById('loadingModal'), { backdrop: 'static', keyboard: false });
        // this.loadingModal.show();
    }

    initialData() {
        let headerNavigateModelList: Array<HeaderNavigateModel> = [
            { label: 'Home', url: '/', attributes: { title: 'Home' } },
            { label: 'Reconcile', url: '/reconcile' },
            { label: 'Transaction', url: '/transaction' }
        ];
        this.headerNavigateService.setNavigate(headerNavigateModelList);

    }

    getTransaction() {


        if (this.searchValidation()) {

            if (this.loadingModal) {
                this.loadingModal.show();
            }
            console.log(this.getValueFormSearch(false));

            this.reconcileService.getReconcileDetail(this.getValueFormSearch(false)).subscribe(
                {
                    next: async (result: any) => {
                        if (!result.data.length && parseInt(result.pageinfo.page) != 1) {
                            this.searchOption.pageinfo.page = 1;
                            this.getTransaction();
                        }
                        else {
                            this.orderData = result.data.map((row: any) => {
                                row.orderNo = row.orderNo.split('-')[1];
                                row.eventDate = moment(row.eventDate).format('DD/MM/YYYY HH:mm:ss');
                                return row;
                            });
                            this.searchOption.pageinfo.page = parseInt(result.pageinfo.page);
                            this.searchOption.pageinfo.rowPerPage = parseInt(result.pageinfo.rowPerPage);
                            this.searchOption.pageinfo.totalRecord = parseInt(result.pageinfo.totalRecord);
                            this.searchOption.pageinfo.allpage = Math.ceil(result.pageinfo.totalRecord / result.pageinfo.rowPerPage);
                            this.currentDatetime = moment().format('DD/MM/YYYY HH:mm:ss');
                        }
                        setTimeout(() => {
                            this.loadingModal.hide();
                        }, 500);
                    },
                    error: (error: any) => {
                        console.log(error);
                        setTimeout(() => {
                            const self = this;
                            const regex = /([.*+?^$|"(){}\[\]])/g;
                            let errorMsg = (error.error.message) ? error.error.message : error.error.error.errorMsg.replaceAll(regex, '');
                            this.messageAlert = {
                                title: 'Warning',
                                message: errorMsg,
                                button: [
                                    {
                                        label: 'Close', action: async function () {
                                            self.messageAlertModal.hide();
                                        }.bind(this)
                                    }]
                            }
                            this.messageAlertModal.show();
                            this.loadingModal.hide();
                        }, 500);
                    }
                }
            );
        }
    }

    searchValidation() {
        let validate = true,
            messageError = '';
        const self = this;
        if (this.searchOption.q.orderNo && (!this.validateNumber(this.searchOption.q.orderNo) || this.searchOption.q.orderNo.length > 20)) {
            validate = false;
            messageError = 'Order No is invalid';
        }
        else if (this.searchOption.q.docNo && this.searchOption.q.docNo.length > 20) {
            validate = false;
            messageError = '\'Doc No.\' is invalid';
        }
        else if ( moment( this.searchOption.q?.startDate).isAfter(moment(this.searchOption.q?.endDate))) {
            validate = false;
            messageError = 'Start Date must not be less than End Date';
        }
        else if (this.searchOption.q.statusSuccess == false &&this.searchOption.q.statusPending == false && this.searchOption.q.statusFail == false ) {
            validate = false;
            messageError = 'Please select least one status.';
        }

        if (!validate) {
            this.messageAlert = {
                title: 'Warning',
                message: messageError,
                button: [
                    {
                        label: 'Close', action: async function () {
                            self.messageAlertModal.hide();
                        }.bind(this)
                    }]
            }
            this.messageAlertModal.show();
        }
        return validate;
    }

    validateNumber(input: any) {
        return this.dataTypeValidationService.validateNumber(input);
    }

    async gotoPage(page: any) {
        if (page > 0 && page <= this.searchOption.pageinfo.allpage) {
            this.searchOption.pageinfo.page = page;
            console.log('=====================');

            this.getTransaction();
        }
    }

    resetOption() {
        this.searchOption.q = {
            process: 'all',
            step: 'get_order_from_nextgen',
            startDate: new Date(),
            endDate: new Date(),
            orderNo: '',
            docNo: '',
            statusSuccess : true,
            statusPending : true,
            statusFail : true
        }
    }

    openView(data: any) {
        this.loadingModal.show();
        console.log(data);

        this.reconcileService.getTransactionLog(data.uId, data.process, data.dataStep).subscribe({
            next: async (result: any) => {
                this.logData = result.data[0];
                setTimeout(() => {
                    this.loadingModal.hide();
                    this.logModal.show();
                }, 500);
            },
            error: async (error: any) => {
                const self = this;
                this.messageAlert = {
                    title: 'Warning',
                    message: error.error.errorMsg,
                    button: [
                        {
                            label: 'Close', action: async function () {
                                self.messageAlertModal.hide();
                            }.bind(this)
                        }]
                }
                setTimeout(() => {
                    this.loadingModal.hide();
                    this.messageAlertModal.show();
                }, 500);
            }
        })
    }

    exportExcel() {
        const self = this;
        this.messageAlert = {
            title: 'Confirm',
            message: 'Export Excel file ?',
            button: [
                {
                    label: 'Yes',
                    color: 'primary',
                    action: async function () {
                        self.exportOrder('xlsx');
                        self.messageAlertModal.hide();
                    }.bind(this)
                },
                {
                    label: 'Cancel', action: async function () {
                        self.messageAlertModal.hide();
                    }.bind(this)
                }
            ]
        }
        this.messageAlertModal.show();
    }

    exportCSV() {
        const self = this;
        this.messageAlert = {
            title: 'Confirm',
            message: 'Export CSV file ?',
            button: [
                {
                    label: 'Yes',
                    color: 'primary',
                    action: async function () {
                        self.exportOrder('csv');
                        self.messageAlertModal.hide();
                    }.bind(this)
                },
                {
                    label: 'Cancel', action: async function () {
                        self.messageAlertModal.hide();
                    }.bind(this)
                }
            ]
        }
        this.messageAlertModal.show();
    }

    exportOrder(exportTo: any = 'xlsx'): any {
        this.loadingModal.show();
        this.reconcileService.getReconcileDetail(this.getValueFormSearch(true)).subscribe(
            {
                next: async (result: any) => {
                    const orders: any = result.data.map((row: any) => {
                        row.orderNo = row.orderNo.split('-')[1];
                        row.eventDate = moment(row.eventDate).format('DD/MM/YYYY HH:mm:ss');
                        return row;
                    });
                    let data: any = [
                        ['uId','process', 'dataStep', 'orderNo', 'Doc No.', 'eventDate', 'INET TransCode', 'eventStatus',' error type', 'LogDesc']
                    ];

                    if (orders.length) {
                        for (let order of orders) {

                            let  log =  await this.getTransactionLog(order);

                            let newRow = [
                                order?.uId,
                                order?.process,
                                order?.dataStep,
                                order?.orderNo,
                                order?.invCnNo,
                                order?.eventDate,
                                order?.transactionCode,
                                order?.eventStatus,
                                log?.errType,
                                log?.errDesc
                             ];
                            data.push(newRow)
                        }
                    }
                    let fileOption: any = { bookType: exportTo, bookSST: true, type: 'binary' },
                        sheetName = 'Sheet1',
                        worksheet = exportFile.utils.json_to_sheet(data, { skipHeader: true }),
                        workbook = exportFile.utils.book_new(),
                        fileName = `export-transaction-${moment().format('DDMMYYYYHHSS')}.${fileOption.bookType}`;

                    exportFile.utils.book_append_sheet(workbook, worksheet, sheetName);
                    exportFile.writeFile(workbook, fileName, fileOption);
                    setTimeout(() => {
                        this.loadingModal.hide();
                    }, 500);
                },
                error: (error: any) => {
                    console.log(error);
                    setTimeout(() => {
                        this.loadingModal.hide();
                    }, 500);
                }
            }
        );

    }


    getValueFormSearch(isExport : boolean) : any {
        let startDate: any = moment(this.searchOption.q.startDate).format('YYYY-MM-DDT00:00:00+07:00'),
        endDate: any = moment(this.searchOption.q.endDate).format('YYYY-MM-DDT23:59:59+07:00');

        let statusAll = [];
        if(this.searchOption.q.statusSuccess){
            statusAll.push('success');
        }
        if(this.searchOption.q.statusPending){
            statusAll.push('pending');
        }
        if(this.searchOption.q.statusFail){
            statusAll.push('failed');
        }

        const option: any = {
            q: {
                process: (this.searchOption.q.process) ? this.searchOption.q.process : null,
                step: (this.searchOption.q.step) ? this.searchOption.q.step : null,
                startDate: (this.searchOption.q.startDate) ? encodeURIComponent(startDate) : null,
                endDate: (this.searchOption.q.endDate) ? encodeURIComponent(endDate) : null,
                orderNo: (this.searchOption.q.orderNo) ? '200-' + this.searchOption.q.orderNo : null,
                docNo: (this.searchOption.q.docNo) ? this.searchOption.q.docNo : null,
                error: (this.searchOption.q.error) ? this.searchOption.q.error : null,
                status: statusAll.join(',')
            },
            pageinfo: isExport ? {
                page: 1,
                rowPerPage: (this.searchOption.pageinfo.totalRecord) ? this.searchOption.pageinfo.totalRecord : 1
            } :  this.searchOption.pageinfo
        }
        return option
    }

    getTransactionLog(data:any):  Promise<any> {
        return new Promise((resolve) => {
            this.reconcileService.getTransactionLog(data.uId, data.process, data.dataStep).subscribe({
                next: async (v: any) => {
                    resolve(v.data[0])
                },
                error: (e) => {
                    resolve({});
                },
            });
        });
    }

}
