// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    session_timeout: 7200,
    // api_url: "https://5rdve344ge.execute-api.ap-southeast-1.amazonaws.com/dev",
    api_url: 'https://dev.etax.amway.co.th',
    client_id: 'dev202204',
    client_secret: 'WHUve7gcJDox62Im',
    secret_key: '3185d15c177648d980bf68a6c7179d1a', // 32 byte // abopool_xn
    secret_iv: '', // abopool_xi
    salt: '777ce50f1c8346e980deab678973511f', // abopool_xs
    okta_login: true,
    okta_client_id: '0oa1q0c4exgOGoT4y0h8',
    okta_issuer: 'https://amway.okta.com/oauth2/default',
    okta_redirect_uri: 'https://dev.etax.amway.co.th/login/callback',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
