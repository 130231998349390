import { LocalService } from './../../core/services/local.service';
import { ReconcileService } from './../../core/services/reconcile.service';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { HeaderNavigateModel } from 'src/app/core/models/common/headernavigate.model';
import { HeaderNavigateService } from 'src/app/core/services/headernavigate.service';
import * as moment from 'moment';
import { Router } from '@angular/router';

declare const bootstrap: any;

@Component({
    selector: 'app-reconcile',
    templateUrl: './reconcile.component.html',
    styleUrls: ['./reconcile.component.scss']
})
export class ReconcileComponent implements OnInit, AfterViewInit, OnDestroy {
    searchOption: any = {
        startDate: new Date(moment().format()),
        endDate: new Date(moment().format())
    };
    minDate: Date = new Date(moment().startOf('month').add(-1, 'month').format());
    maxDate: Date = new Date(moment().endOf('month').format());
    currentDatetime: any = '';
    searched: boolean = false;
    reconcileData: any = [];
    timeOfReface: any = '60';
    autoRefresh: any = false;
    isTime: any = 0;
    messageAlertModal: any;
    messageAlert: any = {
        title: '',
        message: '',
        button: []
    }
    loadingModal: any;
    isDestroy: boolean = false;
    searchByDate: boolean = false;

    constructor(
        private headerNavigateService: HeaderNavigateService,
        private reconcileService: ReconcileService,
        private localService: LocalService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.initialData();
        this.refaceData();
    }

    ngAfterViewInit(): void {
        this.messageAlertModal = new bootstrap.Modal(document.getElementById('messageAlertModal'));
        this.loadingModal = new bootstrap.Modal(document.getElementById('loadingModal'), { backdrop: 'static', keyboard: false });
    }

    ngOnDestroy() {
        const messageAlertModal: any = document.getElementById('messageAlertModal');
        messageAlertModal.remove();
        const loadingModal: any = document.getElementById('loadingModal');
        loadingModal.remove();
        this.isDestroy = true;
    }

    initialData() {
        let headerNavigateModelList: Array<HeaderNavigateModel> = [
            { label: 'Home', url: '/', attributes: { title: 'Home' } },
            { label: 'Reconcile', url: '/reconcile' }
        ];
        this.headerNavigateService.setNavigate(headerNavigateModelList);

    }

    refaceData() {
        if (!this.isDestroy) {
            setTimeout(() => {
                if (this.autoRefresh && this.searched) {
                    if (parseInt(this.timeOfReface) <= this.isTime) {
                        this.getReconcile();
                    }
                    else {
                        this.isTime += 1;
                    }
                }
                this.refaceData();
            }, 1000);
        }
    }

    selectDateOption(event: any) {
        let dateOption: any = event.target.value;
        this.searchByDate = false;
        if (dateOption == 'today') {
            this.searchOption.startDate = new Date(moment().format());
            this.searchOption.endDate = new Date(moment().format());
        }
        else if (dateOption == 'yesterday') {
            this.searchOption.startDate = new Date(moment().add(-1, 'day').format());
            this.searchOption.endDate = new Date(moment().add(-1, 'day').format());
        }
        else if (dateOption == 'this week') {
            this.searchOption.startDate = new Date(moment().startOf('week').add(1, 'day').format());
            this.searchOption.endDate = new Date(moment().endOf('week').add(1, 'day').format());
        }
        else if (dateOption == 'last week') {
            this.searchOption.startDate = new Date(moment().startOf('week').add(-6, 'day').format());
            this.searchOption.endDate = new Date(moment().endOf('week').add(-6, 'day').format());
        }
        else if (dateOption == 'this month') {
            this.searchOption.startDate = new Date(moment().startOf('month').format());
            this.searchOption.endDate = new Date(moment().endOf('month').format());
        }
        else if (dateOption == 'last month') {
            this.searchOption.startDate = new Date(moment().add(-1, 'month').startOf('month').format());
            this.searchOption.endDate = new Date(moment().add(-1, 'month').endOf('month').format());
        }
        else if (dateOption == 'custom date') {
            this.searchByDate = true;
        }
    }

    getReconcile() {
        if (this.searchValidation()) {
            this.loadingModal.show();
            this.isTime = 0;
            let startDate: any = moment(this.searchOption.startDate).format('YYYY-MM-DDT00:00:00+07:00'),
            endDate: any = moment(this.searchOption.endDate).format('YYYY-MM-DDT23:59:59+07:00');
            const option: any = {
                startDate: encodeURIComponent(startDate),
                endDate: encodeURIComponent(endDate)
            }
            this.reconcileService.getReconcile(option).subscribe(
                {
                    next: async (result: any) => {
                        this.reconcileData = result.data;
                        this.currentDatetime = moment().format('DD/MM/YYYY HH:mm:ss');
                        this.searched = true;
                        setTimeout(() => {
                            this.loadingModal.hide();
                        }, 500);
                    },
                    error: (error: any) => {
                        setTimeout(() => {
                            this.loadingModal.hide();
                            const self = this;
                            this.messageAlert = {
                                title: 'Warning',
                                message: error.error.message,
                                button: [
                                    {
                                        label: 'Close', action: async function () {
                                            self.messageAlertModal.hide();
                                        }.bind(this)
                                    }]
                            }
                            this.messageAlertModal.show();
                            this.reconcileData = [];
                            this.searched = false;
                        }, 500);
                    }
                }
            );
        }
    }

    searchValidation() {
        let validate = true;
        if (this.searchOption.startDate > this.searchOption.endDate) {
            validate = false;
        }
        if (!validate) {
            const self = this;
            this.messageAlert = {
                title: 'Warning',
                message: 'Start Date must not be less than End Date',
                button: [
                    {
                        label: 'Close', action: async function () {
                            self.messageAlertModal.hide();
                        }.bind(this)
                    }]
            }
            this.messageAlertModal.show();
        }


        return validate;
    }

    newTabTransaction(processType: any, stepName: any, status: any) {
        processType = (processType == 'Edit From Inet') ? 'edit_invoice' : processType;

        const option: any = {
            process: this.setTextFormatForSearch(processType),
            step: this.setTextFormatForSearch(stepName),
            // status: status,
            statusSuccess: status == 'success',
            statusPending: status == 'pending',
            statusFail: status == 'failed',
            startDate: moment(this.searchOption.startDate).format('YYYY-MM-DDT00:00:00'),
            endDate: moment(this.searchOption.endDate).format('YYYY-MM-DDT23:59:59')
        }
        this.localService.setJsonValue('optionTransaction', JSON.stringify(option));
        const url = this.router.serializeUrl(
            this.router.createUrlTree(['/transaction'])
        )
        window.open(url, '_blank');
    }

    setTextFormatForSearch(str: string) {
        return (str) ? str.toLowerCase().replaceAll(' ', '_') : '';
    }

}
